import React from "react"
import FAQData from "../content/faq.json"
import { Layout } from "../components"

const Faq = () => {
  return (
    <Layout>
      <div className="bg-white">
        <div className="max-w-screen-xl mx-auto pt-12 pb-16 sm:pt-16 sm:pb-20 px-4 sm:px-6 lg:pt-20 lg:pb-28 lg:px-8">
          <h2 className="text-3xl leading-9 font-extrabold text-gray-900">
            {FAQData.title}
          </h2>
          <div className="mt-6 border-t-2 border-gray-100 pt-10">
            <dl className="md:grid md:grid-cols-2 md:gap-8">
              {FAQData.content.map((faq, index) => {
                return (
                  <div key={`faq_item_${index}`}>
                    <div>
                      <dt className="text-lg leading-6 font-medium text-gray-900">
                        {faq.question}
                      </dt>
                      <dd className="mt-2">
                        <p className="text-base leading-6 text-gray-500">
                          {faq.answer}
                        </p>
                      </dd>
                    </div>
                  </div>
                )
              })}
            </dl>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Faq
